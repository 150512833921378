import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/seo';

class Books extends React.Component {
    render() {
        return (
            <Layout>
                <SEO
                    htmlClass="readinglist"
                    title="Reading List"
                    theme="berlin"
                    keywords={[
                        `recommended reading`,
                        `reading list`,
                        `books`,
                        `papers`,
                        `magazines`,
                    ]}
                />
                <header>
                    <h1>Reading List</h1>
                    <p>Some of the most impactful things I've read over the years</p>
                </header>
                <article>
                    <ul className="no-list">
                        <li>
                            {/* prettier-ignore */}
                            <strong><a href="https://3ek2tnfhw4.execute-api.us-west-1.amazonaws.com/default/dispatchLink?id=17d6d13b" target="_blank">Arbitrary Stupid Goal</a>{' '}
                            by Tamara Shopsin</strong>{' '}
                            - Unlike anything I've read before. Delightful and touching. An instant
                            favorite. It resists summary so just read it.
                        </li>

                        <li>
                            {/* prettier-ignore */}
                            <strong><a href="https://3ek2tnfhw4.execute-api.us-west-1.amazonaws.com/default/dispatchLink?id=6657a13e" target="_blank">All Quiet on the Western Front</a>{' '}
                            by Erich M. Remarque</strong>{' '}
                            - Visceral. Shocking. A vivid account of life in the trenches of WWI. I
                            couldn't put this book down.
                        </li>
                        <li>
                            {/* prettier-ignore */}
                            <strong><a href="https://3ek2tnfhw4.execute-api.us-west-1.amazonaws.com/default/dispatchLink?id=7c546d15" target="_blank">The Road Back</a>{' '}
                            by Erich M. Remarque</strong>{' '}
                            - A sequel to "All Quiet" and in many ways more heart-breaking than the
                            first. This book follows the German servicemen of the first world war
                            back to their homeland, where life has moved on without them.
                        </li>
                        <li>
                            <strong>
                                <a
                                    href="https://www.goodreads.com/en/book/show/139069"
                                    target="_blank"
                                >
                                    Endurance: Shackleton's Incredible Voyage
                                </a>{' '}
                                by Alfred Lansing
                            </strong>{' '}
                            - An account of Shackleton's failed 1914 attempt to cross Antarctica.
                            Putting the hubris of such an expedition aside, the story of the crew's
                            survival is just incredible. Delivered well{' '}
                            <a
                                href="https://www.audible.com/pd/Endurance-Audiobook/B002V9ZA6C"
                                target="_blank"
                            >
                                as an audible performance
                            </a>{' '}
                            by Simon Prebble.
                        </li>
                        <li>
                            {/* prettier-ignore */}
                            <strong><a href="https://3ek2tnfhw4.execute-api.us-west-1.amazonaws.com/default/dispatchLink?id=74cf2265" target="_blank">The Handmaid’s Tale</a>{' '}
                            by Margaret Atwood</strong>{' '}
                            - A masterpiece. Atwood reveals just enough to balance hope with
                            unspeakable dread.
                        </li>
                        <li>
                            {/* prettier-ignore */}
                            <strong><a href="https://3ek2tnfhw4.execute-api.us-west-1.amazonaws.com/default/dispatchLink?id=e7de6080" target="_blank">The Book You Wish Your Parents Had Read</a>{' '}

                            by Philippa Perry</strong>{' '}
                            - If there's one book you read on parenting, let it be this. Highly
                            practical advice.
                        </li>

                        <li>
                            {/* prettier-ignore */}
                            <strong><a href="https://3ek2tnfhw4.execute-api.us-west-1.amazonaws.com/default/dispatchLink?id=458853c0" target="_blank">Tales from the Loop</a>{' '}
                            by Simon Stålenhag</strong>{' '}
                            - Retro-futurism at its finest. Conceptually and visually brilliant.
                        </li>
                        <li>
                            {/* prettier-ignore */}
                            <strong><a href="https://medium.com/@bkoflife/on-marrying-the-wrong-person-1c0b3e57ef31" target="_blank">How we end up marrying the wrong people</a>{' '}
                            by Alain de Botton</strong>{' '}
                            - Essential reading for life partners.
                        </li>
                        <li>
                            <strong>
                                <a href="https://qntm.org/mmacevedo" target="_blank">
                                    Lena
                                </a>{' '}
                                by qntm
                            </strong>{' '}
                            - The realest take on what will happen if consciousness can ever be
                            captured and replicated in a machine. I haven't been so moved since
                            Brooker's{' '}
                            <a href="https://www.youtube.com/watch?v=iiHKnpiXm-o" target="_blank">
                                Christmas Special
                            </a>
                            . Deep, deep horror.
                        </li>
                        <li>
                            {/* prettier-ignore */}
                            <strong><a href="http://galactanet.com/oneoff/theegg_mod.html" target="_blank">The Egg</a>{' '}
                            by Andy Weir</strong>{' '}
                            - A riff on the meaning of life.
                        </li>
                    </ul>
                </article>
                <article>
                    <p>
                        <Link to="/">
                            <span className="arrow">←</span>&nbsp;&nbsp;Back to the rest of the
                            website.
                        </Link>
                    </p>
                </article>
            </Layout>
        );
    }
}

export default Books;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;
